import React from "react";
import Seo from "components/Seo";
import MainTitle from "components/MainTitle";
import LayoutTerms, { Content } from "components/LayoutTerms";
import { Term, TermItem, TermTitle } from "components/Term";

export default function AmlCftTerms(props) {
  return (
    <LayoutTerms {...props}>
      <Seo title="Termos de Uso AML-CFT" description="Termos de uso sobre práticas AML-CFT no Tecimob." />
      <MainTitle titleTag="h1" title="Termos de Uso AML-CFT" />
      <Content>
        <Term>
          <TermItem>
            <TermTitle>1. Introdução e Definições</TermTitle>
            <p><strong>1.1. AML-CFT</strong></p>
            <p>AML-CFT refere-se às práticas e políticas destinadas a prevenir a lavagem de dinheiro e o financiamento do terrorismo. Estas práticas são fundamentais para garantir a integridade e a segurança das operações financeiras dentro do sistema <strong>TECIMOB.</strong></p>
            <p><strong>1.2. Termos Importantes</strong></p>
            <ul>
              <li>Cliente: Pessoa física ou jurídica que utiliza o SOFTWARE TECIMOB.</li>
              <li>Transações Suspeitas: Movimentações financeiras que possam indicar lavagem de dinheiro ou financiamento de atividades terroristas.</li>
              <li>Devido Conhecimento (CDD): Processo de verificação da identidade do Cliente e compreensão das suas atividades financeiras.</li>
            </ul>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>2. Obrigações do Cliente</TermTitle>
            <p><strong>2.1. Fornecimento de Informações Precisas</strong></p>
            <p>O Cliente deve fornecer informações verdadeiras, completas e atualizadas durante o cadastro e em todas as interações com o SOFTWARE TECIMOB. Isso inclui, mas não se limita a, dados pessoais, informações financeiras e documentos de identificação.</p>
            <p><strong>2.2. Cooperação com a LICENCIANTE</strong></p>
            <p>O Cliente concorda em cooperar plenamente com a LICENCIANTE em qualquer investigação relacionada a atividades suspeitas. Isso inclui fornecer documentação adicional e responder a solicitações de informação de forma oportuna.</p>
            <p><strong>2.3. Monitoramento de Transações</strong></p>
            <p>O Cliente é responsável por monitorar suas próprias transações realizadas através do SOFTWARE TECIMOB e relatar quaisquer atividades suspeitas imediatamente à LICENCIANTE.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>3. Obrigações da LICENCIANTE</TermTitle>
            <p><strong>3.1. Implementação de Políticas AML-CFT</strong></p>
            <p>A LICENCIANTE implementará e manterá políticas e procedimentos eficazes para detectar, prevenir e reportar atividades relacionadas à lavagem de dinheiro e financiamento do terrorismo.</p>
            <p><strong>3.2. Due Diligence</strong></p>
            <p>A LICENCIANTE realizará processos de Devido Conhecimento (CDD) para verificar a identidade dos Clientes e entender a natureza das suas atividades comerciais. Isso inclui a coleta de informações básicas e a análise de transações para identificar padrões suspeitos.</p>
            <p><strong>3.3. Relatórios de Atividades Suspeitas</strong></p>
            <p>A LICENCIANTE está obrigada a reportar quaisquer transações suspeitas às autoridades competentes, conforme exigido pela legislação brasileira vigente.</p>
            <p><strong>3.4. Treinamento e Conscientização</strong></p>
            <p>A LICENCIANTE fornecerá treinamento contínuo para seus colaboradores sobre as práticas de AML-CFT, garantindo que todos estejam cientes das suas responsabilidades e das melhores práticas para identificar e prevenir atividades ilícitas.</p>
          </TermItem>
        </Term>
        <Term>
         <TermItem>
           <TermTitle>4. Proibições</TermTitle>
           <p><strong>4.1. Atividades Ilícitas</strong></p>
           <p>É estritamente proibido utilizar o SOFTWARE TECIMOB para facilitar, executar ou disfarçar qualquer atividade ilícita, incluindo, mas não se limitando a, lavagem de dinheiro e financiamento do terrorismo.</p>
           <p><strong>4.2. Tentativas de Manipulação</strong></p>
           <p>Qualquer tentativa de enganar, manipular ou contornar os sistemas de detecção de atividades suspeitas da LICENCIANTE será considerada uma violação grave destes Termos de Uso e poderá resultar na rescisão imediata do contrato, sem direito a reembolso.</p>
         </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>5. Segurança e Proteção de Dados</TermTitle>
            <p><strong>5.1. Proteção de Informações</strong></p>
            <p>A LICENCIANTE adotará medidas técnicas e administrativas adequadas para proteger as informações dos Clientes contra acesso não autorizado, perda, destruição ou alteração.</p>
            <p><strong>5.2. Privacidade dos Dados</strong></p>
            <p>Todas as informações coletadas no âmbito das políticas de AML-CFT serão tratadas de acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD), garantindo a privacidade e a segurança dos dados dos Clientes.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>6. Consequências de Violação</TermTitle>
            <p><strong>6.1. Rescisão do Contrato</strong></p>
            <p>A violação das obrigações relacionadas a AML-CFT por parte do Cliente resultará na rescisão imediata do contrato de uso do SOFTWARE TECIMOB, sem direito a reembolso.</p>
            <p><strong>6.2. Responsabilidade Legal</strong></p>
            <p>O Cliente poderá ser responsabilizado legalmente por quaisquer danos ou prejuízos decorrentes do uso indevido do SOFTWARE TECIMOB para fins ilícitos.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>7. Atualizações das Políticas AML-CFT</TermTitle>
            <p>A LICENCIANTE reserva-se o direito de atualizar estas políticas de AML-CFT conforme necessário para garantir conformidade com a legislação vigente e as melhores práticas do setor. Quaisquer alterações serão comunicadas aos Clientes de forma adequada.</p>
            <p>Data da Última Revisão do Documento:</p>
            <p>Tubarão, 09 de Dezembro de 2024.</p>
          </TermItem>
        </Term>
        <Term>
          <TermItem>
            <TermTitle>AML-CFT Terms of Use</TermTitle>
            <p><strong>1. Introduction and Definitions</strong></p>
            <p><strong>1.1. AML-CFT</strong></p>
            <p>AML-CFT refers to the practices and policies aimed at preventing money laundering and the financing of terrorism. These practices are essential to ensure the integrity and security of financial operations within the TECIMOB system.</p>
            <p><strong>1.2. Important Terms</strong></p>
            <p>Client: An individual or legal entity that uses the TECIMOB SOFTWARE.</p>
            <p>Suspicious Transactions: Financial movements that may indicate money laundering or the financing of terrorist activities.</p>
            <p>Customer Due Diligence (CDD): The process of verifying the Client's identity and understanding their financial activities.</p>
            <p><strong>2. Client Obligations</strong></p>
            <p><strong>2.1. Provision of Accurate Information</strong></p>
            <p>The Client must provide truthful, complete, and updated information during registration and in all interactions with the TECIMOB SOFTWARE. This includes, but is not limited to, personal data, financial information, and identification documents.</p>
            <p><strong>2.2. Cooperation with the LICENSOR</strong></p>
            <p>The Client agrees to fully cooperate with the LICENSOR in any investigation related to suspicious activities. This includes providing additional documentation and responding to information requests promptly.</p>
            <p><strong>2.3. Transaction Monitoring</strong></p>
            <p>The Client is responsible for monitoring their own transactions conducted through the TECIMOB SOFTWARE and reporting any suspicious activities immediately to the LICENSOR.</p>
            <p><strong>3. Licensor Obligations</strong></p>
            <p><strong>3.1. Implementation of AML-CFT Policies</strong></p>
            <p>The LICENSOR will implement and maintain effective policies and procedures to detect, prevent, and report activities related to money laundering and the financing of terrorism.</p>
            <p><strong>3.2. Due Diligence</strong></p>
            <p>The LICENSOR will conduct Customer Due Diligence (CDD) processes to verify the identity of Clients and understand the nature of their business activities. This includes collecting basic information and analyzing transactions to identify suspicious patterns.</p>
            <p><strong>3.3. Reporting of Suspicious Activities</strong></p>
            <p>The LICENSOR is obliged to report any suspicious transactions to the competent authorities as required by current Brazilian legislation.</p>
            <p><strong>3.4. Training and Awareness</strong></p>
            <p>The LICENSOR will provide ongoing training to its employees on AML-CFT practices, ensuring that everyone is aware of their responsibilities and best practices for identifying and preventing illicit activities.</p>
            <p><strong>4. Prohibitions</strong></p>
            <p><strong>4.1. Illicit Activities</strong></p>
            <p>It is strictly prohibited to use the TECIMOB SOFTWARE to facilitate, execute, or disguise any illicit activity, including but not limited to money laundering and the financing of terrorism.</p>
            <p><strong>4.2. Attempts to Manipulate</strong></p>
            <p>Any attempt to deceive, manipulate, or circumvent the LICENSOR's suspicious activity detection systems will be considered a serious violation of these Terms of Use and may result in the immediate termination of the contract without refund.</p>
            <p><strong>5. Data Security and Protection</strong></p>
            <p><strong>5.1. Information Protection</strong></p>
            <p>The LICENSOR will adopt appropriate technical and administrative measures to protect Clients' information against unauthorized access, loss, destruction, or alteration.</p>
            <p><strong>5.2. Data Privacy</strong></p>
            <p>All information collected under AML-CFT policies will be handled in accordance with the General Data Protection Law (LGPD), ensuring the privacy and security of Clients' data.</p>
            <p><strong>6. Consequences of Violation</strong></p>
            <p><strong>6.1. Contract Termination</strong></p>
            <p>Violation of AML-CFT obligations by the Client will result in the immediate termination of the TECIMOB SOFTWARE usage contract without refund.</p>
            <p><strong>6.2. Legal Responsibility</strong></p>
            <p>The Client may be held legally responsible for any damages or losses resulting from the improper use of the TECIMOB SOFTWARE for illicit purposes.</p>
            <p><strong>7. Updates to AML-CFT Policies</strong></p>
            <p>The LICENSOR reserves the right to update these AML-CFT policies as necessary to ensure compliance with current legislation and industry best practices. Any changes will be communicated to Clients appropriately.</p>

            <p>Date of Last Document Review:</p>
            <p>Tubarão, December 09, 2024.</p>
          </TermItem>
        </Term>




      </Content>
    </LayoutTerms>
  );
}
